import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
import { Label, Input, Textarea, Button } from "theme-ui";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Kontakt" description="@todo" mdxType="SEO" />
    <h1 {...{
      "id": "kontakt"
    }}>{`Kontakt`}</h1>
    <h2 {...{
      "id": "chcete-nám-napsat"
    }}>{`Chcete nám napsat?`}</h2>
    <form name="kontakt" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
      <input type="hidden" name="form-name" value="kontakt" />
      <Label htmlFor='name' mdxType="Label">Vaše jméno: </Label>
      <Input type="text" name="name" mb={3} mdxType="Input" />
      <Label htmlFor="email" mdxType="Label">Vaše e-mail adresa:</Label>
      <Input type="email" name="email" mb={3} mdxType="Input" />
      <Label htmlFor="telefon" mdxType="Label">Váš telefon: </Label>
      <Input type="telefon" name="telefon" mb={3} mdxType="Input" />
      <Label htmlFor="zprava" mdxType="Label">Zpráva pro nás: </Label>
      <Textarea name="zprava" mb={3} mdxType="Textarea"></Textarea>
      <Button type="submit" mdxType="Button">Odeslat</Button>
    </form>
    <h2 {...{
      "id": "nebo-nás-navštívit"
    }}>{`Nebo nás navštívit?`}</h2>
    <h3 {...{
      "id": "adresa-provozovny-a-místo-pro-osobní-vyzvednutí"
    }}>{`Adresa provozovny a místo pro osobní vyzvednutí`}</h3>
    <p><strong parentName="p">{`Potoční 170/12, Kutná Hora`}</strong>{` (`}<a href="https://en.mapy.cz/s/jejopucuvo" target="\_blank" rel="noopener noreferrer">{` otevřít mapu `}</a>{`
)`}</p>
    <div {...{
      "className": "gatsby-resp-iframe-wrapper",
      "style": {
        "paddingBottom": "50%",
        "position": "relative",
        "height": "0",
        "overflow": "hidden"
      }
    }}>{` `}<iframe parentName="div" {...{
        "style": {
          "border": "none",
          "position": "absolute",
          "top": "0",
          "left": "0",
          "width": "100%",
          "height": "100%"
        },
        "src": "https://en.frame.mapy.cz/s/hafudufage",
        "frameBorder": "0"
      }}></iframe>{` `}</div>
    <h3 {...{
      "id": "provozní-doba"
    }}>{`Provozní doba`}</h3>
    <p>{`Pondělí až pátek, od 08:00 do 20:00`}</p>
    <h2 {...{
      "id": "nebo-raději-rovnou-zavolat"
    }}>{`Nebo raději rovnou zavolat?`}</h2>
    <h3 {...{
      "id": "miroslav-kukla"
    }}>{`Miroslav Kukla`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}></th>
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`mobil:`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "tel:+420724211123",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`+420 724 211 123`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`e-mail:`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "mailto:miroslav.kukla@obalyrak.cz",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`miroslav.kukla@obalyrak.cz`}</a></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "fakturační-údaje"
    }}>{`Fakturační údaje`}</h2>
    <p>{`Název společnosti: `}<strong parentName="p">{`R&K spol. s r.o. rychle a kvalitně`}</strong></p>
    <p>{`Sídlo společnosti: `}<strong parentName="p">{`Klokočná 4, 251 64 Mnichovice`}</strong></p>
    <p>{`IČ: `}<strong parentName="p">{`14802490`}</strong></p>
    <p>{`DIČ: `}<strong parentName="p">{`CZ14802490`}</strong>{` (plátce DPH)`}</p>
    <p><em parentName="p">{`Firma je zapsána v obchodním rejstříku vedeném Městským soudem v Praze oddíl C, vložka 1370`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      